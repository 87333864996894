<template>
    <a-card class="hello" style="padding:0;">
        <a-tabs default-active-key="1" :size="size">
            <a-tab-pane key="1" tab="TCE估算">
                <a-tabs default-active-key="1" :size="size">
                    <a-tab-pane key="1" tab="重载最大盈利航速">
                        <max-speed />
                        <result-info />
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="空载最大盈利航速" disabled>
                    </a-tab-pane>
                </a-tabs>
                <bottom-info style="margin-top:20px;" />
            </a-tab-pane>
            <a-tab-pane key="2" tab="油耗矩阵">
                <table-view />
            </a-tab-pane>
        </a-tabs>
    </a-card>
</template>

<script>
import BottomInfo from "./BottomInfo.vue"
import MaxSpeed from "./MaxSpeed.vue"
import ResultInfo from "./ResultInfo.vue"
import TableView from "./yhjz/TableView.vue"
export default {
    components: {
        MaxSpeed,
        ResultInfo,
        TableView,
        BottomInfo,
    },
    name: "Main",
    data() {
        return {
            size: "small",
        }
    },
    props: {
        msg: String,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hello {
    text-align: left;
    padding: 0;
}
.ant-card-body {
    padding: 0;
}

* {
    border-radius: 0;
}
.ant-tabs-bar {
    margin-bottom: 4px;
}
</style>
