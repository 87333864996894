<template>
    <div class="container">
        <a-table
            :columns="columns"
            size="small"
            :data-source="data"
            :pagination="false"
            :loading="loading"
        />
    </div>
</template>

<script>
// 季节
const columns = [
    {
        title: "季节",
        dataIndex: "season",
        width: 150,
        customRender: (value, row, index) => {
            const obj = {
                children: value,
                attrs: {},
            }
            if (index % 3 === 0) {
                obj.attrs.rowSpan = 3
            } else {
                obj.attrs.rowSpan = 0
            }
            return obj
        },
    },
    {
        title: "数据项",
        dataIndex: "title",
    },

    {
        title: "具体参数",
        dataIndex: "p1",
        colSpan: 9,
    },
    {
        title: "具体参数",
        dataIndex: "p2",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p3",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p4",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p5",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p6",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p7",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p8",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p9",
        colSpan: 0,
    },
]

// 空载数据
const speedArr = [11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15]
const speedArr2 = [10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14]

const sourceData1 = [
    {
        load: "空载",
        season: "春天（3至5月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [1.17, 1.51, 2.64, 6.43, 13.57, 19.3, 15.02, 9, 5.3],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    37.74,
                    38.37,
                    40.08,
                    40.35,
                    41.18,
                    42.21,
                    43.52,
                    45.97,
                    49.35,
                ],
            },
        ],
    },
    {
        load: "空载",
        season: "夏天（6至8月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [8.38, 9.6, 10.08, 9.72, 7.99, 6.15, 5.2, 2.74, 1.39],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    39.44,
                    41.07,
                    41.62,
                    41.77,
                    41.68,
                    43.08,
                    44.76,
                    48.82,
                    49.14,
                ],
            },
        ],
    },
    {
        load: "空载",
        season: "秋天（9至11月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0.13, 0.76, 1.92, 2.43, 3.15, 3.14, 1.97, 0.82, 0.33],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    38.58,
                    38.73,
                    38.22,
                    39.28,
                    39.06,
                    39.87,
                    42.93,
                    44.23,
                    60.21,
                ],
            },
        ],
    },

    {
        load: "空载",
        season: "冬天（12至2月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0, 0, 0.03, 0.08, 0.18, 0.23, 0.25, 0.16, 0.21],
            },
            {
                k: "日均油耗(ton/day)",
                v: ["NA", "NA", 42.8, 44.65, 53.34, 54.88, 59.53, 59.37, 62.35],
            },
        ],
    },
    {
        load: "重载",
        season: "春天（3至5月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.07, 0.29, 1.53, 4.38, 9.67, 11.78, 11.7, 9.79, 3.35],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    40.91,
                    44.42,
                    44.05,
                    45.03,
                    46.12,
                    48.21,
                    50.77,
                    55.67,
                    58.98,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "夏天（6至8月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.48, 0.95, 2.06, 4.67, 7.2, 9.78, 9.89, 3.69, 1.12],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    43.25,
                    43.71,
                    45.83,
                    45.9,
                    47.12,
                    49.51,
                    50.43,
                    51.6,
                    54.86,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "秋天（9至11月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.65, 2.21, 4.41, 6.45, 12.53, 14.62, 9.13, 9.25, 5.97],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    44.64,
                    45.59,
                    47.85,
                    49.78,
                    49.14,
                    49.22,
                    53.75,
                    61.06,
                    64.22,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "冬天（12至2月）",
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.17, 0.27, 1.34, 2.23, 2.72, 3.78, 2.57, 1, 0.14],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    43.19,
                    49.25,
                    51.02,
                    52.85,
                    53.77,
                    54.78,
                    54.73,
                    53.82,
                    53.39,
                ],
            },
        ],
    },
]

// 天气
const columns2 = [
    {
        title: "天气状况",
        width: 100,
        dataIndex: "season",
        customRender: (value, row, index) => {
            const obj = {
                children: value,
                attrs: {},
            }
            if (index % 3 === 0) {
                obj.attrs.rowSpan = 3
            } else {
                obj.attrs.rowSpan = 0
            }
            return obj
        },
    },
    {
        title: "滑失率",
        width: 150,
        dataIndex: "sliprate",
        customRender: (value, row, index) => {
            const obj = {
                children: value,
                attrs: {},
            }
            if (index % 3 === 0) {
                obj.attrs.rowSpan = 3
            } else {
                obj.attrs.rowSpan = 0
            }
            return obj
        },
    },
    {
        title: "累计时间(天)",
        dataIndex: "days",
        width: 100,
        customRender: (value, row, index) => {
            const obj = {
                children: value,
                attrs: {},
            }
            if (index % 3 === 0) {
                obj.attrs.rowSpan = 3
            } else {
                obj.attrs.rowSpan = 0
            }
            return obj
        },
    },
    {
        title: "具体参数",
        width: 200,
        dataIndex: "title",
        colSpan: 10,
    },
    {
        title: "具体参数",
        dataIndex: "p1",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p2",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p3",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p4",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p5",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p6",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p7",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p8",
        colSpan: 0,
    },
    {
        title: "具体参数",
        dataIndex: "p9",
        colSpan: 0,
    },
]

// 天气情况数据
const sourceData2 = [
    {
        load: "空载",
        season: "极佳",
        sliprate: "-20%以下",
        days: 5.68,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0.03, 0.03, 0.03, 0.23, 0.04, 0.06, 0.15, 1.95, 3.16],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    17.36,
                    30.43,
                    29.32,
                    26.46,
                    32.4,
                    34.16,
                    33.91,
                    39.94,
                    40.65,
                ],
            },
        ],
    },
    {
        load: "空载",
        season: "很好",
        days: 21.54,
        sliprate: "-20%~-14%",
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0, 0.49, 0.73, 0.03, 0.08, 1.57, 11.67, 5.89, 1.08],
            },
            {
                k: "日均油耗(ton/day)",
                v: [NaN, 23.79, 23.96, 24.8, 32.52, 35.71, 39.75, 41.4, 47.09],
            },
        ],
    },
    {
        load: "空载",
        season: "较好",
        sliprate: "-14.0%~-10.0%",
        days: 47.55,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0.28, 2.4, 0.07, 0.05, 5.87, 25.45, 8.97, 2.64, 1.82],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    23.53,
                    23.79,
                    28.54,
                    32.62,
                    36.09,
                    39.35,
                    42.08,
                    47.43,
                    52.14,
                ],
            },
        ],
    },
    {
        load: "空载",
        sliprate: "-10.0%~-6.0%",
        season: "良好",
        days: 48.08,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [2.33, 0.5, 0.12, 5.71, 23.78, 8.08, 3.45, 2.76, 1.35],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    23.89,
                    28.44,
                    33.39,
                    36.41,
                    38.97,
                    42.05,
                    47.82,
                    52.65,
                    58.08,
                ],
            },
        ],
    },
    {
        load: "空载",
        season: "中等",
        sliprate: "-6.0%~-1.0%",
        days: 36.18,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0.58, 0.03, 5.06, 18.19, 5.23, 2.86, 2.52, 1.04, 0.67],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    27.46,
                    31.28,
                    37.38,
                    39.07,
                    42.3,
                    48.78,
                    53.04,
                    58.61,
                    64.94,
                ],
            },
        ],
    },
    {
        load: "空载",
        season: "较差",
        sliprate: "-1.0%~5.0%",
        days: 26.03,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [0.24, 8.01, 12.07, 2.24, 1.67, 1.08, 0.56, 0.13, 0.03],
            },
            {
                k: "日均油耗(ton/day)",
                v: [37.22, 38.52, 39.47, 46.14, 51.6, 58.98, 64, 66.53, 67.58],
            },
        ],
    },
    {
        load: "空载",
        season: "很差",
        sliprate: "5.0%~12.0%",
        days: 20.1,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [8.93, 5.46, 2.22, 2.05, 0.71, 0.57, 0.16, 0, 0],
            },
            {
                k: "日均油耗(ton/day)",
                v: [40.27, 42.56, 52.72, 56.9, 63.15, 68.07, 69.93, NaN, NaN],
            },
        ],
    },
    {
        load: "空载",
        season: "恶劣",
        sliprate: "12%以上",
        days: 3.7,
        data: [
            {
                k: "航速(kn)",
                v: speedArr,
            },
            {
                k: "累计时间(天)",
                v: [1.63, 1.6, 0.36, 0.08, 0.03, 0, 0, 0, 0],
            },
            {
                k: "日均油耗(ton/day)",
                v: [56.51, 58.53, 63.29, 66.68, 68.64, NaN, NaN, NaN, NaN],
            },
        ],
    },
    {
        load: "重载",
        season: "极佳",
        sliprate: "-25%以下",
        days: 0.22,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0, 0, 0, 0.03, 0.03, 0.07, 0.03, 0.03, 0.03],
            },
            {
                k: "日均油耗(ton/day)",
                v: [NaN, NaN, NaN, 33.96, 36.18, 37.46, 49.64, 54.03, 53.04],
            },
        ],
    },
    {
        load: "重载",
        season: "很好",
        days: 5,
        sliprate: "-25%~-7%",
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.1, 0.03, 0.11, 0.14, 0.19, 0.49, 1.44, 1.67, 0.83],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    19.26,
                    18.64,
                    24.63,
                    26.12,
                    42.28,
                    40.74,
                    43.29,
                    43.7,
                    45.91,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "较好",
        sliprate: "-7.0%~-1.0%",
        days: 32.09,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.03, 0.13, 0.03, 0.25, 2.15, 6.57, 9.28, 7.81, 5.84],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    24.82,
                    25.8,
                    22.92,
                    39.32,
                    40.11,
                    42.51,
                    46.74,
                    51.36,
                    55.98,
                ],
            },
        ],
    },
    {
        load: "重载",
        sliprate: "-1.0%~-3.5%",
        season: "良好",
        days: 77.73,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.16, 0.03, 0.18, 1.59, 7.39, 16.19, 19.21, 24.5, 8.48],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    26.61,
                    27.6,
                    35.32,
                    39.68,
                    42.33,
                    47.18,
                    52.28,
                    56.95,
                    63.5,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "中等",
        sliprate: "3.5%~-7.0%",
        days: 97.36,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.05, 0.14, 1.18, 5.48, 15.55, 20.45, 30.33, 21.24, 2.94],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    26.86,
                    37.39,
                    39.64,
                    41.97,
                    47.2,
                    52.88,
                    57.44,
                    64.38,
                    67.6,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "较差",
        sliprate: "7.0%~12.5%",
        days: 70.37,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [0.11, 0.95, 5.01, 10.82, 15.38, 23.25, 12.19, 2.66, 0],
            },
            {
                k: "日均油耗(ton/day)",
                v: [
                    36.59,
                    40.98,
                    44.14,
                    48.64,
                    54.85,
                    59.74,
                    66.13,
                    70.06,
                    NaN,
                ],
            },
        ],
    },
    {
        load: "重载",
        season: "很差",
        sliprate: "12.5%~21.5%",
        days: 34.41,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [1.36, 4.21, 8.24, 9.02, 8.73, 2.82, 0.03, 0, 0],
            },
            {
                k: "日均油耗(ton/day)",
                v: [43.98, 48.91, 54.37, 62.2, 64.93, 69.83, 72.48, NaN, NaN],
            },
        ],
    },
    {
        load: "重载",
        season: "恶劣",
        sliprate: "21.5%以上",
        days: 3.59,
        data: [
            {
                k: "航速(kn)",
                v: speedArr2,
            },
            {
                k: "累计时间(天)",
                v: [1.59, 1.38, 0.58, 0.04, 0, 0, 0, 0, 0],
            },
            {
                k: "日均油耗(ton/day)",
                v: [58.35, 61.76, 68.89, 75.1, NaN, NaN, NaN, NaN, NaN],
            },
        ],
    },
]

export default {
    name: "DataForSeason",
    data() {
        return {
            data: [],
            columns,
            loading: true,
        }
    },
    props: {
        loadState: String,
        computeMode: String,
    },
    mounted() {
        setInterval(() => {
            this.loading = false
        }, 1500)
    },
    methods: {
        refreshData() {
            this.loading = true
            this.data = []
            setInterval(() => {
                this.generalData()
                this.loading = false
            }, 1500)
        },
        generalData() {
            const data = []
            if (this.computeMode === "按季节计算") {
                this.columns = columns
                sourceData1.forEach((v, i) => {
                    const load = v.load
                    const speedObj = v.data[0]
                    const dayObj = v.data[1]
                    const avgObj = v.data[2]
                    if (load === this.loadState) {
                        data.push({
                            key: i * 3 + 1,
                            season: v.season,
                            title: speedObj.k,
                            p1: speedObj.v[0],
                            p2: speedObj.v[1],
                            p3: speedObj.v[2],
                            p4: speedObj.v[3],
                            p5: speedObj.v[4],
                            p6: speedObj.v[5],
                            p7: speedObj.v[6],
                            p8: speedObj.v[7],
                            p9: speedObj.v[8],
                        })

                        data.push({
                            key: i * 3 + 2,
                            season: v.season,
                            title: dayObj.k,
                            p1: dayObj.v[0],
                            p2: dayObj.v[1],
                            p3: dayObj.v[2],
                            p4: dayObj.v[3],
                            p5: dayObj.v[4],
                            p6: dayObj.v[5],
                            p7: dayObj.v[6],
                            p8: dayObj.v[7],
                            p9: dayObj.v[8],
                        })

                        data.push({
                            key: i * 3 + 3,
                            season: v.season,
                            title: avgObj.k,
                            p1: avgObj.v[0],
                            p2: avgObj.v[1],
                            p3: avgObj.v[2],
                            p4: avgObj.v[3],
                            p5: avgObj.v[4],
                            p6: avgObj.v[5],
                            p7: avgObj.v[6],
                            p8: avgObj.v[7],
                            p9: avgObj.v[8],
                        })
                    }
                })
            } else {
                // 按天气情况
                this.columns = columns2
                sourceData2.forEach((v, i) => {
                    const load = v.load
                    const speedObj = v.data[0]
                    const dayObj = v.data[1]
                    const avgObj = v.data[2]
                    if (load === this.loadState) {
                        data.push({
                            key: i * 3 + 1,
                            season: v.season,
                            title: speedObj.k,
                            sliprate: v.sliprate,
                            days: v.days,
                            p1: speedObj.v[0],
                            p2: speedObj.v[1],
                            p3: speedObj.v[2],
                            p4: speedObj.v[3],
                            p5: speedObj.v[4],
                            p6: speedObj.v[5],
                            p7: speedObj.v[6],
                            p8: speedObj.v[7],
                            p9: speedObj.v[8],
                        })
                        data.push({
                            key: i * 3 + 2,
                            season: v.season,
                            sliprate: v.sliprate,
                            days: v.days,
                            title: dayObj.k,
                            p1: dayObj.v[0],
                            p2: dayObj.v[1],
                            p3: dayObj.v[2],
                            p4: dayObj.v[3],
                            p5: dayObj.v[4],
                            p6: dayObj.v[5],
                            p7: dayObj.v[6],
                            p8: dayObj.v[7],
                            p9: dayObj.v[8],
                        })
                        data.push({
                            key: i * 3 + 3,
                            season: v.season,
                            sliprate: v.sliprate,
                            days: v.days,
                            title: avgObj.k,
                            p1: avgObj.v[0],
                            p2: avgObj.v[1],
                            p3: avgObj.v[2],
                            p4: avgObj.v[3],
                            p5: avgObj.v[4],
                            p6: avgObj.v[5],
                            p7: avgObj.v[6],
                            p8: avgObj.v[7],
                            p9: avgObj.v[8],
                        })
                        // data.push({
                        //     key: i * 3 + 4,
                        //     season: v.season,
                        //     title: slipUpper.k,
                        //     p1: slipUpper.v[0],
                        //     p2: slipUpper.v[1],
                        //     p3: slipUpper.v[2],
                        //     p4: slipUpper.v[3],
                        //     p5: slipUpper.v[4],
                        //     p6: slipUpper.v[5],
                        //     p7: slipUpper.v[6],
                        //     p8: slipUpper.v[7],
                        //     p9: slipUpper.v[8],
                        // })
                        // data.push({
                        //     key: i * 3 + 5,
                        //     season: v.season,
                        //     title: slipLimit.k,
                        //     p1: slipLimit.v[0],
                        //     p2: slipLimit.v[1],
                        //     p3: slipLimit.v[2],
                        //     p4: slipLimit.v[3],
                        //     p5: slipLimit.v[4],
                        //     p6: slipLimit.v[5],
                        //     p7: slipLimit.v[6],
                        //     p8: slipLimit.v[7],
                        //     p9: slipLimit.v[8],
                        // })
                    }
                })
            }
            this.data = data
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
    display: block;
    padding: 20px;
}
</style>
