<template>
    <a-row>
        <a-col :span="12"><table-data /></a-col>
        <a-col :span="12">
            <chart-data />
        </a-col>
    </a-row>
</template>

<script>
import ChartData from "./ChartData.vue"
import TableData from "./TableData.vue"
export default {
    components: { TableData, ChartData },
    name: "Main",
    data() {
        return {
            size: "small",
        }
    },
    props: {
        msg: String,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
