<template>
    <a-card class="container">
        <div class="title">结论</div>
        <div class="info">
            <p class="msg">11 KNOTS</p>
            <p class="desc">最大 重载 盈利航速</p>
        </div>

        <div class="info">
            <p class="msg">17,008 $/D</p>
            <p class="desc">TCE</p>
        </div>
    </a-card>
</template>

<script>
export default {
    name: "ResultInfo",
    props: {
        msg: String,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
    display: block;
    height: 70px;
    background: rgba(0, 255, 0, 0.05);
    border-top: solid 1px #eee;
    padding: 0 40px;
}

.container .title {
    float: left;
    font-size: 24px;
    line-height: 70px;
    width: 10%;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.container .info {
    display: inline-block;
    width: 40%;
    border-left: solid 1px #ddd;
    margin: 15px 0;
    height: 40px;
    padding-left: 40px;
}

.container .info p {
    margin: 0;
    color: rgba(9, 185, 77);
    font-weight: 400;
}

.container .info .msg {
    font-size: 28px;
    line-height: 28px;
}

.container .info .desc {
    font-size: 12px;
}
</style>
