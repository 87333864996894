<template>
    <div class="container">
        <a-row>
            <a-col :span="12">
                <a-form
                    :form="form"
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 12 }"
                    @submit="handleSubmit"
                >
                    <a-form-item label="空载航速">
                        <a-input
                            default-value="12"
                            v-decorator="[
                                'note',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                            addon-after="KNOTS"
                        />
                    </a-form-item>
                    <a-form-item label="空载HFO每日油耗">
                        <a-input
                            default-value="41.5"
                            v-decorator="[
                                'note',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                            addon-after="MT/D"
                        />
                    </a-form-item>
                    <a-form-item label="空载MDO每日油耗">
                        <a-input
                            default-value="0"
                            v-decorator="[
                                'note',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                            addon-after="MT/D"
                        />
                    </a-form-item>
                    <a-form-item label="WS点数">
                        <a-input
                            default-value="21.9"
                            v-decorator="[
                                'ws',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                            addon-after="%"
                        />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button
                            type="link"
                            style="padding:0;"
                            @click="handleSetParams"
                        >
                            配置常量参数
                        </a-button>
                    </a-form-item>
                </a-form>
            </a-col>
            <a-col :span="12">
                <a-form
                    :form="form2"
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 12 }"
                    @submit="handleSubmit"
                >
                    <a-form-item label="重载油耗矩阵">
                        <a-select
                            default-value="按天气情况"
                            style="width: 35%;"
                        >
                            <a-select-option value="按天气情况">
                                按天气情况
                            </a-select-option>
                            <a-select-option value="按季节计算">
                                按季节计算
                            </a-select-option>
                        </a-select>
                        <a-select
                            default-value="较差-(滑失率:7.0%~12.5%)"
                            style="width: 65%;"
                        >
                            <a-select-option value="极佳">
                                极佳-(滑失率:-25%以下)
                            </a-select-option>
                            <a-select-option value="很好">
                                很好-(滑失率:-25%~-7%)
                            </a-select-option>
                            <a-select-option value="较好">
                                较好-(滑失率:-7.0%~-1.0%)
                            </a-select-option>
                            <a-select-option value="良好">
                                良好-(滑失率:-1.0%~-3.5%)
                            </a-select-option>
                            <a-select-option value="中等">
                                中等-(滑失率:3.5%~-7.0%)
                            </a-select-option>
                            <a-select-option value="较差">
                                较差-(滑失率:7.0%~12.5%)
                            </a-select-option>
                            <a-select-option value="很差">
                                很差-(滑失率:12.5%~21.5%)
                            </a-select-option>
                            <a-select-option value="恶劣">
                                恶劣-(滑失率:21.5%以上)
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <!-- <a-form-item label="重载HFO每日油耗">
                        <a-input
                            default-value="50.0"
                            addon-after="MT/D"
                            v-decorator="[
                                'note',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                        />
                    </a-form-item> -->
                    <a-form-item label="重载MDO每日油耗">
                        <a-input
                            default-value="0"
                            addon-after="MT/D"
                            v-decorator="[
                                'note',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                        />
                    </a-form-item>
                    <a-form-item label="HFO燃油价格">
                        <a-input
                            default-value="300.0"
                            addon-after="$/MT"
                            v-decorator="[
                                'ws',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '该项为必填项',
                                        },
                                    ],
                                },
                            ]"
                        />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button type="primary" @click="handleSubmit">
                            计算
                        </a-button>
                        <a-button
                            type="default"
                            style="margin-left:10px;"
                            @click="handleReset"
                        >
                            重置
                        </a-button>
                    </a-form-item>
                </a-form>
            </a-col>
        </a-row>
        <param-editor ref="editor" />
    </div>
</template>

<script>
import ParamEditor from "./ParamEditor.vue"
export default {
    components: { ParamEditor },
    name: "MaxSpeed",
    data() {
        return {
            formLayout: "horizontal",
            form: this.$form.createForm(this, { name: "coordinated" }),
            form2: this.$form.createForm(this, { name: "coordinated2" }),
            loading: false,
        }
    },
    props: {
        msg: String,
    },
    computed: {
        count() {
            return this.expand ? 11 : 7
        },
    },
    updated() {
        console.log("updated")
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.loading = true
            setInterval(() => {
                this.loading = false
            }, 1500)
            // this.form.validateFields((err, values) => {
            //     if (!err) {
            //         console.log("Received values of form: ", values)
            //     }
            // })
        },
        handleSetParams() {
            this.$message.success("开发中...")
            // this.$refs.editor.showDrawer()
        },
        handleReset() {
            this.form.resetFields()
            this.form2.resetFields()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container {
    display: block;
}

.ant-input-group-addon {
    width: 72px;
    text-align: left;
}

.ant-form-item {
    margin-bottom: 0;
}
</style>
