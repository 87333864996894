import Vue from "vue"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
import App from "./App.vue"
import echarts from "echarts"

Vue.use(Antd)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
    render: (h) => h(App),
}).$mount("#app")
