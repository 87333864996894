<template>
    <div id="components-table-demo-size">
        <a-table :columns="columns" :data-source="data" :pagination="false">
            <span slot="serial" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    index + 1
                }}</span>
                <span v-else>{{ index + 1 }}</span>
            </span>

            <span slot="speed" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    v
                }}</span>
                <span v-else>{{ v }}</span>
            </span>

            <span slot="days" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    v
                }}</span>
                <span v-else>{{ v }}</span>
            </span>

            <span slot="tce" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    v
                }}</span>
                <span v-else>{{ v }}</span>
            </span>

            <span slot="pc" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    v
                }}</span>
                <span style="color:red;" v-else>{{ v }}</span>
            </span>

            <span slot="sr" slot-scope="v, item, index">
                <span style="color:rgba(9, 185, 77);" v-if="index === 2">{{
                    v
                }}</span>
                <span v-else>{{ v }}</span>
            </span>
        </a-table>
    </div>
</template>

<script>
const columns = [
    {
        title: "序号",
        dataIndex: "serial",
        align: "center",
        scopedSlots: { customRender: "serial" },
    },
    {
        title: "重载航速(KNOTS)",
        dataIndex: "speed",
        scopedSlots: { customRender: "speed" },
    },
    {
        title: "总航行天数(days)",
        dataIndex: "days",
        scopedSlots: { customRender: "days" },
    },
    {
        title: "TCE($/D)",
        dataIndex: "tce",
        scopedSlots: { customRender: "tce" },
    },
    {
        title: "TCE偏差值($)",
        dataIndex: "pc",
        scopedSlots: { customRender: "pc" },
    },
    {
        title: "净运费收入($)",
        dataIndex: "sr",
        scopedSlots: { customRender: "sr" },
    },
]
const data = [
    {
        key: "1",
        serial: 1,
        days: "53.95",
        speed: 10,
        tce: "16,865",
        pc: "-143",
        sr: "909,823.88",
    },
    {
        key: "2",
        serial: 2,
        days: "52.75",
        speed: 10.5,
        tce: "16,841",
        pc: "-167",
        sr: "888,430.41",
    },
    {
        key: "3",
        serial: 3,
        days: "51.67",
        speed: 11,
        tce: "17,008",
        pc: "0",
        sr: "878,787.58",
    },
    {
        key: "4",
        serial: 4,
        days: "50.68",
        speed: 11.5,
        tce: "16,966",
        pc: "-42",
        sr: "859,810.91",
    },
    {
        key: "5",
        serial: 5,
        days: "49.77",
        speed: 12,
        tce: "16,674",
        pc: "-334",
        sr: "829,871.93",
    },
    {
        key: "6",
        serial: 6,
        days: "48.94",
        speed: 12.5,
        tce: "16,585",
        pc: "-423",
        sr: "811,587.11",
    },
    {
        key: "7",
        serial: 7,
        days: "48.16",
        speed: 13,
        tce: "16,290",
        pc: "-718",
        sr: "784,589.31",
    },
    {
        key: "8",
        serial: 8,
        days: "47.45",
        speed: 13.5,
        tce: "16,345",
        pc: "-663",
        sr: "775,567.60",
    },
]
export default {
    name: "TableData",
    props: {
        msg: String,
    },
    data() {
        return {
            data,
            columns,
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#components-table-demo-size {
    /* padding: 10px 40px 0 40px; */
}

.ant-table-small {
    border: none;
}
</style>
