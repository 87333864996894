<template>
    <div class="container">
        <a-form layout="inline" :form="form" @submit="handleSubmit">
            <a-form-item label="筛选条件">
                <a-select
                    :default-value="'重载'"
                    style="width: 150px"
                    placeholder="请选择"
                    v-model="load"
                >
                    <a-select-option v-for="load in loadStatus" :key="load">
                        {{ load }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-select
                    :default-value="'按天气状况'"
                    style="width: 150px"
                    placeholder="请选择"
                    v-model="weather"
                >
                    <a-select-option v-for="m in methodData" :key="m">
                        {{ m }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" html-type="submit">
                    查询
                </a-button>
                <a-button
                    type="default"
                    style="margin-left:10px;"
                    html-type="submit"
                >
                    重置
                </a-button>
            </a-form-item>
        </a-form>
        <a-divider style="margin:10px 0 0 0;" />
        <data-for-season
            ref="season"
            :loadState="load"
            :computeMode="weather"
        />
    </div>
</template>

<script>
import DataForSeason from "./DataForSeason.vue"
export default {
    components: { DataForSeason },
    name: "TableView",
    data() {
        return {
            formLayout: "horizontal",
            form: this.$form.createForm(this, { name: "coordinated" }),
            loadStatus: ["重载", "空载"],
            methodData: ["按天气状况", "按季节计算"],
            weatherFlg: true,
            LoadFlg: true,
            weather: "按天气情况",
            load: "重载",
        }
    },
    props: {
        msg: String,
    },
    mounted() {
        this.refreshTable()
        console.log(1)
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.refreshTable()
            console.log(this.weather, this.load)
        },
        // handleConditionChange(v) {
        //     if (v === "按天气情况") {
        //         this.weatherFlg = true
        //     } else {
        //         this.weatherFlg = false
        //     }
        // },
        // handleSelectLoadStatus(v) {
        //     if (v === "重载") {
        //         this.LoadFlg = true
        //     } else {
        //         this.LoadFlg = false
        //     }
        // },
        refreshTable() {
            if (this.weather === "按天气情况") {
                this.weatherFlg = true
            } else {
                this.weatherFlg = false
            }

            if (this.load === "重载") {
                this.LoadFlg = true
            } else {
                this.LoadFlg = false
            }
            this.$refs.season.refreshData()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container {
    display: block;
    padding: 0 20px;
}

.ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td {
    padding: 4px 8px;
}
</style>
