<template>
    <div class="chart-container">
        <a-tabs default-active-key="a" @change="handleTabs">
            <a-tab-pane key="a" tab="TCE">
                <div style="height:440px;" id="chart"></div>
            </a-tab-pane>
            <a-tab-pane key="b" tab="净运费收入" :forceRender="true">
                <div style="height:440px;" id="chart2"></div>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
const option = {
    title: {
        text: "",
    },
    tooltip: {
        trigger: "axis",
    },
    grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
    },
    toolbox: {},
    xAxis: {
        name: "航速",
        type: "category",
        boundaryGap: false,
        data: [9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5],
        nameTextStyle: {
            padding: [0, 0, 0, -10], // 四个数字分别为上右下左与原位置距离
        },
    },
    yAxis: {
        type: "value",
        name: "TCE",
        min: 16000,
    },
    series: [
        {
            name: "TCE",
            type: "line",
            data: [
                null,
                16865,
                16841,
                17008,
                16966,
                16674,
                16585,
                16290,
                16345,
            ],
            itemStyle: {
                normal: {
                    color: "#8cd5c2", //改变折线点的颜色
                    lineStyle: {
                        color: "#8cd5c2", //改变折线颜色
                    },
                },
            },
        },
    ],
}

const option2 = {
    title: {
        text: "",
    },
    tooltip: {
        trigger: "axis",
    },
    grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
    },
    toolbox: {},
    xAxis: {
        name: "航速",
        type: "category",
        boundaryGap: false,
        data: [9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5],
        nameTextStyle: {
            padding: [0, 0, 0, -10], // 四个数字分别为上右下左与原位置距离
        },
    },
    yAxis: {
        type: "value",
        name: "净运费收入",
        min: 700000,
        lineStyle: {
            color: "blue",
        },
    },
    series: [
        {
            name: "净运费收入",
            type: "line",
            data: [
                null,
                909823.88,
                888430.41,
                878787.58,
                859810.91,
                829871.93,
                811587.11,
                784589.31,
                775567.6,
            ],
        },
    ],
}
export default {
    name: "ChartData",
    props: {
        msg: String,
    },
    mounted() {
        this.myEcharts()
    },
    methods: {
        myEcharts() {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById("chart"))

            myChart.setOption(option)

            // 使用刚指定的配置项和数据显示图表。
        },
        handleTabs(k) {
            if (k === "a") {
                var myChart = this.$echarts.init(
                    document.getElementById("chart")
                )
                myChart.setOption(option)
            } else {
                var myChart2 = this.$echarts.init(
                    document.getElementById("chart2")
                )
                myChart2.setOption(option2)
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart-container {
    height: 490px;
}

#chart {
    height: 100%;
}
</style>
