var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-drawer',{attrs:{"title":"TCE计算参数设置","width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'name',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please enter user name',
                                        } ],
                                } ]),expression:"[\n                                'name',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please enter user name',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Please enter user name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'url',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'please enter url',
                                        } ],
                                } ]),expression:"[\n                                'url',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: 'please enter url',\n                                        },\n                                    ],\n                                },\n                            ]"}],staticStyle:{"width":"100%"},attrs:{"addon-before":"http://","addon-after":".com","placeholder":"please enter url"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Owner"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'owner',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please select an owner',
                                        } ],
                                } ]),expression:"[\n                                'owner',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please select an owner',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Please a-s an owner"}},[_c('a-select-option',{attrs:{"value":"xiao"}},[_vm._v(" Xiaoxiao Fu ")]),_c('a-select-option',{attrs:{"value":"mao"}},[_vm._v(" Maomao Zhou ")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Type"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'type',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please choose the type',
                                        } ],
                                } ]),expression:"[\n                                'type',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please choose the type',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Please choose the type"}},[_c('a-select-option',{attrs:{"value":"private"}},[_vm._v(" Private ")]),_c('a-select-option',{attrs:{"value":"public"}},[_vm._v(" Public ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Approver"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'approver',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please choose the approver',
                                        } ],
                                } ]),expression:"[\n                                'approver',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please choose the approver',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Please choose the approver"}},[_c('a-select-option',{attrs:{"value":"jack"}},[_vm._v(" Jack Ma ")]),_c('a-select-option',{attrs:{"value":"tom"}},[_vm._v(" Tom Liu ")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"DateTime"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'dateTime',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please choose the dateTime',
                                        } ],
                                } ]),expression:"[\n                                'dateTime',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please choose the dateTime',\n                                        },\n                                    ],\n                                },\n                            ]"}],staticStyle:{"width":"100%"},attrs:{"get-popup-container":function (trigger) { return trigger.parentNode; }}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'description',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please enter url description',
                                        } ],
                                } ]),expression:"[\n                                'description',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message:\n                                                'Please enter url description',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"rows":4,"placeholder":"please enter url description"}})],1)],1)],1)],1),_c('div',{style:({
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
            })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" Cancel ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onClose}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }