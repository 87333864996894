<template>
    <div>
        <a-drawer
            title="TCE计算参数设置"
            :width="720"
            :visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
        >
            <a-form :form="form" layout="vertical" hide-required-mark>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="Name">
                            <a-input
                                v-decorator="[
                                    'name',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please enter user name',
                                            },
                                        ],
                                    },
                                ]"
                                placeholder="Please enter user name"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="Url">
                            <a-input
                                v-decorator="[
                                    'url',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'please enter url',
                                            },
                                        ],
                                    },
                                ]"
                                style="width: 100%"
                                addon-before="http://"
                                addon-after=".com"
                                placeholder="please enter url"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="Owner">
                            <a-select
                                v-decorator="[
                                    'owner',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please select an owner',
                                            },
                                        ],
                                    },
                                ]"
                                placeholder="Please a-s an owner"
                            >
                                <a-select-option value="xiao">
                                    Xiaoxiao Fu
                                </a-select-option>
                                <a-select-option value="mao">
                                    Maomao Zhou
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="Type">
                            <a-select
                                v-decorator="[
                                    'type',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please choose the type',
                                            },
                                        ],
                                    },
                                ]"
                                placeholder="Please choose the type"
                            >
                                <a-select-option value="private">
                                    Private
                                </a-select-option>
                                <a-select-option value="public">
                                    Public
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="Approver">
                            <a-select
                                v-decorator="[
                                    'approver',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please choose the approver',
                                            },
                                        ],
                                    },
                                ]"
                                placeholder="Please choose the approver"
                            >
                                <a-select-option value="jack">
                                    Jack Ma
                                </a-select-option>
                                <a-select-option value="tom">
                                    Tom Liu
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="DateTime">
                            <a-date-picker
                                v-decorator="[
                                    'dateTime',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please choose the dateTime',
                                            },
                                        ],
                                    },
                                ]"
                                style="width: 100%"
                                :get-popup-container="
                                    (trigger) => trigger.parentNode
                                "
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="Description">
                            <a-textarea
                                v-decorator="[
                                    'description',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please enter url description',
                                            },
                                        ],
                                    },
                                ]"
                                :rows="4"
                                placeholder="please enter url description"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div
                :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }"
            >
                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    Cancel
                </a-button>
                <a-button type="primary" @click="onClose">
                    Submit
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            visible: false,
        }
    },
    methods: {
        showDrawer() {
            this.visible = true
        },
        onClose() {
            this.visible = false
        },
    },
}
</script>
